require('./bootstrap');

const menu__fleche__icon = document.querySelectorAll('.menu__fleche__icon');
menu__fleche__icon.forEach(function(anchor) {

    const item__menu = anchor.closest('.nav-link');

    item__menu.addEventListener('click', function(event) {
        if (!anchor.matches(".menu__fleche__icon")) {
            return;
        }

        const target__anchor = anchor;

        if (target__anchor.classList.contains('rotate180')) {
            target__anchor.classList.remove('rotate180');
        } else {
            target__anchor.classList.add('rotate180');
        }
    })
});

const tout_deb = document.getElementById('tout-deb');
const tout_deb_menu = document.getElementById('tout-deb-menu');

tout_deb.addEventListener('click', function(e) {
    if (tout_deb_menu.classList.contains('tout-deb-menu-active')) {
        tout_deb_menu.classList.remove("tout-deb-menu-active");
    } else {
        tout_deb_menu.classList.toggle("tout-deb-menu-active");
    }

});